import Head from 'next/head';
import { useEffect } from 'react';
import { useUiDispatch } from 'stores/ui';
import { getCountryCode } from 'utils/countries';
import { getEnvConfiguration } from 'utils/env-configuration';
import setLowPriorityTask from 'utils/setLowPriorityTask';

const ANDROID_PACKAGE_ID_KEY = 'ANDROID_PACKAGE_ID';
const IOS_APP_ID_KEY = 'IOS_APP_ID';

interface Props {
  destination: string;
}

export const UniversalLinks = ({ destination }: Props) => {
  const androidPackageId = getEnvConfiguration(ANDROID_PACKAGE_ID_KEY);
  const iosAppId = getEnvConfiguration(IOS_APP_ID_KEY);
  const countryCode = getCountryCode()?.toLowerCase();
  const uiDispatch = useUiDispatch();

  useEffect(() => {
    setLowPriorityTask(() => {
      // Save deeplink to store that will be used by AppSmartBanner
      uiDispatch.setDeeplink(`zalora://${countryCode}/${destination}`);
    });
  }, [destination, uiDispatch, countryCode]);

  return (
    <Head>
      <link
        rel="alternate"
        href={`android-app://${androidPackageId}/zalora/${countryCode}/${destination}`}
      />
      <link
        rel="alternate"
        href={`ios-app://${iosAppId}/zalora/${countryCode}/${destination}`}
      />
    </Head>
  );
};
