import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { memo } from 'react';
import { Robots } from 'utils/catalog/types';
import { getDomainByLocale } from 'utils/domains';

type Props = {
  title: string;
  description?: string;
  robots?: string;
  canonical: string;
};

export const PageInfoComponent = ({ title, description, robots, canonical }: Props) => {
  const { locale = '' } = useRouter();
  const domain = getDomainByLocale(locale);

  return (
    <Head>
      <title key="page:title">{title}</title>

      <meta
        name="description"
        key="page:description"
        content={description || ''}
      />
      <meta
        name="robots"
        key="page:robots"
        content={robots || Robots.INDEX_FOLLOW}
      />
      <link
        rel="canonical"
        key="page:canonical"
        href={`${domain}${canonical}`}
      />
    </Head>
  );
};

export const PageInfo = memo(PageInfoComponent);
