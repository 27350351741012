const setLowPriorityTask = (callback: () => unknown) => {
  if (!window) {
    return;
  }

  if ('requestIdleCallback' in window) {
    requestIdleCallback(callback);
  } else {
    setTimeout(callback, 0);
  }
};

export default setLowPriorityTask;
