import { ZDTProduct } from '@zalora/doraemon-ts';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { Fragment } from 'react';
import { ProductMediaType } from 'types/ZDTEnums';
import { getAbsoluteUrl } from 'utils/url';

type Props = {
  title: string;
  description?: string;
  url?: string;
  image?: string[] | string;
  medias?: ZDTProduct.Media[];
  imageWidth?: string;
  imageHeight?: string;
};

const MEDIA_TYPES = {
  video: 'video',
  image: 'image',
};

export const OpenGraphTags = ({
  title,
  description = '',
  url = '',
  image,
  medias,
  imageWidth,
  imageHeight,
}: Props) => {
  const router = useRouter();
  const imageUrls = typeof image === 'string' ? [image] : image;

  return (
    <Head>
      <meta
        property="og:title"
        content={title}
        key="og:title"
      />

      {description ? (
        <meta
          property="og:description"
          content={description}
          key="og:description"
        />
      ) : null}

      <meta
        property="og:type"
        content="website"
        key="og:type"
      />

      {url ? (
        <meta
          property="og:url"
          content={getAbsoluteUrl(url, router.locale as string)}
          key="og:url"
        />
      ) : null}

      {imageUrls?.map((image, index) => (
        <Fragment key={`og:image_${index}`}>
          <meta
            property={'og:image'}
            content={image}
          />

          {imageWidth ? (
            <meta
              property="og:image:width"
              content={imageWidth}
            />
          ) : null}

          {imageHeight ? (
            <meta
              property="og:image:height"
              content={imageHeight}
            />
          ) : null}
        </Fragment>
      ))}

      {medias?.map((item, index) => (
        <meta
          property={`og:${
            item.ContentType === ProductMediaType.PRODUCT_MEDIA_TYPE_VIDEO
              ? MEDIA_TYPES.video
              : MEDIA_TYPES.image
          }`}
          content={item.Url || undefined}
          key={`MetaMedia_${index}`}
        />
      ))}
    </Head>
  );
};
