import Head from 'next/head';
import { ScriptHTMLAttributes } from 'react';

interface Props extends ScriptHTMLAttributes<HTMLScriptElement> {
  jsonLd: Record<string, unknown>;
  id: string;
}

export const StructuredData = ({ jsonLd, id, ...props }: Props) => {
  return (
    <Head>
      <script
        {...props}
        id={id}
        key={id}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      />
    </Head>
  );
};
