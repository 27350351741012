export const enum StockStatus {
  IN_STOCK = 0,
  LOW_IN_STOCK = 1,
  LAST_ITEM_IN_STOCK = 2,
  OUT_OF_STOCK = 3,
}
export const enum ProductMediaType {
  PRODUCT_MEDIA_TYPE_IMAGE = 0,
  PRODUCT_MEDIA_TYPE_VIDEO = 1,
}
export const enum ProductType {
  ZALORA = 0,
  SPONSORED = 1,
  BANNER_TILE = 2,
}
